import { useTokenContext } from "~/store/token";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxios } from "./_common";
import { SatLevelApi } from "./meta";
import { SatApi } from "./sat";
import { RoleApi } from "./roles";
import { PersonApi } from "./people";

export interface UserGoal {
  date: string;
  expiration: string;
  satTitle: string;
  title: string;
  goal_level: SatLevelApi | null;
  verifiedSatLevel: SatLevelApi | null;
  id: number;
  notes: string;
}

export interface UserSat {
  id: number;
  Title: string;
  goals: {
    Date: string;
    Expiration: string;
    satTitle: string;
    Title: string;
    goal_level: SatLevelApi | null;
    verifiedSatLevel: SatLevelApi | null;
    id: number;
    notes: string;
  }[];
  sat: SatApi;
  inReviewSatLevel: SatLevelApi | null;
  verifiedSatLevel: SatLevelApi | null;
  goalSatLevel: SatLevelApi | null;
}

export type UserRole = {
  Main?: boolean;
  Title: string;
  employeeRole: RoleApi;
};

export type UserAnagraphic = {
  id: number;
  username: string;
  email: string;
  onboardCompleted: boolean;
  Picture: string;
  GivenName: string;
  FamilyName: string;
  isReviewer: boolean;
};

export type UserMentorSponsorApi = {
  id: number;
};

export type UserMentorSponsor = {
  Mentee: UserMentorSponsorApi[];
  Mentor: UserMentorSponsorApi[];
  Sponsee: UserMentorSponsorApi[];
  Sponsor: UserMentorSponsorApi[];
  isMentor: boolean;
  isSponsor: boolean;
};

export type User = UserMentorSponsor &
  UserAnagraphic & {
    Roles: UserRole[];
    Sats: UserSat[];
  };

export function useUser() {
  const { get } = useAxios();
  const { token } = useTokenContext();
  return useQuery(
    "user-me",
    () => get<User>("/users/me").then(res => res.data),
    {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 30 * 60 * 1000
    }
  );
}

export function useUserAnagraphic() {
  const { get } = useAxios();
  const { token } = useTokenContext();
  return useQuery(
    "user-me-anagraphic",
    () =>
      get<UserAnagraphic>(
        "/users/me?fields=id,username,email,onboardCompleted,Picture,GivenName,FamilyName,isReviewer"
      ).then(res => res.data),
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
      cacheTime: 30 * 60 * 1000
    }
  );
}

export function useUserSatLevels() {
  const { get } = useAxios();
  const { token } = useTokenContext();
  return useQuery(
    "user-sat-levels",
    () =>
      get<{ Sats: UserSat[] }>(`/users/me?fields=Sats`).then(
        res => res.data.Sats
      ),
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
      cacheTime: 15 * 60 * 1000
    }
  );
}

export function useUserGoals(personData?: PersonApi): {
  data?: UserGoal[];
  previewData: [UserGoal, UserGoal, UserGoal];
} {
  const data = personData?.Sats?.map(item =>
    item.goals.map(goal => ({
      title: goal.Title,
      date: goal.Date,
      expiration: goal.Expiration,
      goal_level: goal.goal_level,
      id: goal.id,
      notes: goal.notes,
      satTitle: item.Title,
      verifiedSatLevel: item.verifiedSatLevel
    }))
  ).flatMap(item => item);

  const previewData = data?.slice(0, 3) as [UserGoal, UserGoal, UserGoal];

  return { data, previewData };
}

export function useUserMentorSponsor() {
  const { get } = useAxios();
  const { token } = useTokenContext();
  return useQuery(
    "user-mentor-sponsor",
    () =>
      get<UserMentorSponsor>(
        `/users/me?fields=Mentee,Mentor,Sponsee,Sponsor,isMentor,isSponsor`
      ).then(res => res.data),
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
      cacheTime: 15 * 60 * 1000
    }
  );
}

export function useUserUpdateGoogle() {
  const { get } = useAxios();
  return useMutation((variables: { accessToken: string }) =>
    get(
      `/users/me${
        !!variables.accessToken ? `?access_token=${variables.accessToken}` : ""
      }`
    ).then(res => res.data)
  );
}

export type GetOnboardSatsApi = {
  skill: string | null;
  tool: string | null;
  activity: string | null;
};

export function useUserOnboardCompleted() {
  const { post } = useAxios();
  const queryClient = useQueryClient();
  return useMutation<GetOnboardSatsApi>(
    "user-onboard-complete",
    () =>
      post("/users/onboard-complete", {
        body: JSON.stringify({})
      }).then(res => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries("user-me")
    }
  );
}

export type AddRoleIdApi = {
  id: number;
  requestSats?: boolean;
  isMain?: boolean;
};

export function useUserAddRole() {
  const { post } = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    "user-add-role",
    async (variables: AddRoleIdApi) =>
      await post(`/users/role/${variables.id}`, {
        requested_sats: Boolean(variables.requestSats),
        main: Boolean(variables.isMain)
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("user-me")
    }
  );
}

export type UserAddSatLevelApi = {
  id: number;
  body: {
    sat_level_id: number;
    description: string;
  };
};

export function useUserAddSatLevel() {
  const { post } = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    "user-add-sat-level",
    (variables: UserAddSatLevelApi) =>
      post(`/users/sat/${variables.id}`, { ...variables.body }),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries("user-me");
        queryClient.invalidateQueries(["user-sat", variables.id]);
      }
    }
  );
}

export type UserAddSatGoalApi = {
  id: number;
  body: {
    sat_level_id: number;
    notes: string;
    expires: string;
  };
};

export function useUserAddSatGoal() {
  const { post } = useAxios();
  const queryClient = useQueryClient();
  return useMutation(
    "user-add-sat-level",
    (variables: UserAddSatGoalApi) =>
      post(
        `/users/sat/${variables.id}/goal`,
        JSON.stringify({ ...variables.body })
      ),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries("user-me");
        queryClient.invalidateQueries(["user-sat", variables.id]);
      }
    }
  );
}

export function useUserSatById(id: number) {
  const { get } = useAxios();
  const { token } = useTokenContext();
  return useQuery(
    ["user-sat", id],
    () => get(`/users/sat/${id}`).then(res => res.data),
    {
      enabled: !!token,
      refetchOnMount: false
    }
  );
}

export type UserRoleCompletionApi = {
  completed: number;
  progress: number;
  total: number;
};

export function useUserRoleCompletion(id: number) {
  const { get } = useAxios();
  const { token } = useTokenContext();
  return useQuery(
    ["user-role-completion", id],
    () =>
      get<UserRoleCompletionApi>(`/users/role/${id}/sats/completed`).then(
        res => res.data
      ),
    {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}
