import { TranslationsKeys } from "~/i18n";

const routes: {
  id: string;
  label: TranslationsKeys;
  path: string;
  "data-test-id": string;
}[] = [
  {
    id: "roles",
    label: "Header.Menu.Roles",
    path: "/roles",
    "data-test-id": "header-navigation-item-roles"
  },
  {
    id: "skills",
    label: "Header.Menu.Skills",
    path: "/skill",
    "data-test-id": "header-navigation-item-skill"
  },
  {
    id: "activities",
    label: "Header.Menu.Activities",
    path: "/activity",
    "data-test-id": "header-navigation-item-activity"
  },
  {
    id: "tools",
    label: "Header.Menu.Tools",
    path: "/tool",
    "data-test-id": "header-navigation-item-tool"
  },
  {
    id: "people",
    label: "Header.Menu.People",
    path: "/people",
    "data-test-id": "header-navigation-item-people"
  }
];

export default routes;
