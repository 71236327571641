import { Suspense } from "react";
import { AppProvider } from "./AppProvider";
import { AppRouter } from "./AppRouter";
import ErrorBoundry from "./ErrorBoundry";
import TagManager from "react-gtm-module";
import { RouterLoadingScreen } from "~/blocks/LoadingScreen/RouterLoadingScreen";

const tagManagerArgs = {
  gtmId: "GTM-MPCGX9M"
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <ErrorBoundry>
      <Suspense fallback={<RouterLoadingScreen />}>
        <AppProvider>
          <AppRouter />
        </AppProvider>
      </Suspense>
    </ErrorBoundry>
  );
}

export default App;
