import { PureComponent } from "react";
import { Box, Heading, Space } from "@fifthbeat/design-system";
import { Link } from "react-router-dom";
import { Layout } from "./blocks/Layout/Layout";
import { Column } from "~/components/Grid/Column";

type State = {
  hasError: boolean;
};

export default class ErrorBoundry extends PureComponent<{}, State> {
  public readonly state: State = {
    hasError: false
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    console.error(error);
    console.info(info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout>
          <Column>
            <Box column>
              <Heading size="large" weight="bold">
                Something happened and it's our fault...
              </Heading>
              <Space units={4} />
              <Heading size="small" weight="regular">
                We constantly work really hard to provide you the best
                experience, but sometiemes we fail.
              </Heading>
              <Space units={6} />
              <Link to="/">
                <Heading size="small" weight="regular">
                  Get back in the flow
                </Heading>
              </Link>
            </Box>
          </Column>
        </Layout>
      );
    } else {
      return this.props.children;
    }
  }
}
