import { Snackbar } from "@fifthbeat/design-system";
import {
  ComponentProps,
  useEffect,
  createContext,
  useContext,
  useState
} from "react";

export type AlertType = ComponentProps<typeof Snackbar> & {};

const useAlert = () => {
  const [alerts, setAlerts] = useState<AlertType[]>([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlerts(alerts => [...alerts].slice(0, -1));
    }, 5000);
    return () => clearTimeout(timeout);
  }, [alerts]);

  return {
    alerts,
    addAlert: (alert: AlertType) =>
      setAlerts(prevAlerts => [...(prevAlerts || []), alert])
  };
};

const AlertContext = createContext<ReturnType<typeof useAlert> | null>(null);

export const useAlertContext = () => useContext(AlertContext)!;

export function AlertProvider({ children }: { children: React.ReactNode }) {
  return (
    <AlertContext.Provider value={useAlert()}>{children}</AlertContext.Provider>
  );
}
