import { useTokenContext } from "./../store/token";
import axios from "axios";
import { backendBaseUrl } from "../utils/_envVars";
import { useEffect } from "react";

const http = axios.create({
  baseURL: backendBaseUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json"
  }
});

export function useAxios() {
  const { token } = useTokenContext();

  useEffect(() => {
    http.interceptors.request.use(config => {
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        };
      }
      return config;
    });
  }, [token]);

  return http;
}
