import { createContext, useContext, useMemo, useState } from "react";

const useToken = () => {
  const [token, setToken] = useState<string>();

  function saveToken(token: string) {
    !!token && localStorage.setItem("token", token);
    !!token && setToken(token);
  }

  function clearToken() {
    setToken(undefined);
    localStorage.removeItem("token");
  }

  function parseJwt(token?: string) {
    if (!token) return;

    var base64Url = `${token}`.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  function verifyToken() {
    if (!token) {
      clearToken();
    } else {
      const jwt = parseJwt(token);
      const exp = jwt.exp;
      const now = new Date().getTime() / 1000;
      if (exp < now) {
        window.location.pathname = "/login";
      }
    }
  }

  return useMemo(
    () => ({
      token,
      saveToken,
      clearToken,
      verifyToken
    }),
    [token]
  );
};

const TokenContext = createContext<ReturnType<typeof useToken> | null>(null);

export const useTokenContext = () => useContext(TokenContext)!;

export function TokenProvider({ children }: { children: React.ReactNode }) {
  return (
    <TokenContext.Provider value={useToken()}>{children}</TokenContext.Provider>
  );
}
