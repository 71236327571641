import { Snackbar, Stack } from "@fifthbeat/design-system";
import usePortal from "react-useportal";
import { useAlertContext } from "~/store/alert";
import * as classes from "./AlertsBox.css";

export function AlertsBox() {
  const { alerts } = useAlertContext();
  const { Portal } = usePortal({
    bindTo: (document && document.getElementById("alert")) || undefined
  });
  return (
    <Portal>
      <Stack column className={classes.portal} units={2}>
        {alerts?.map(a => (
          <Snackbar key={a.id} {...a} />
        ))}
      </Stack>
    </Portal>
  );
}
