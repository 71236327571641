import { Column } from "~/components/Grid/Column";
import { Box, Body, Space, palette } from "@fifthbeat/design-system";
import { useTranslation } from "~/i18n";
import { NavLink } from "react-router-dom";
import * as classes from "./Footer.css";
import { useResponsive } from "~/hooks/useResponsive";

export function Footer() {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  return (
    <Box column component="footer" className={classes.wrapper}>
      <Space units={6} />
      <Column>
        <Box column={isMobile} vAlignContent="center">
          <Body
            size="xx-small"
            color={palette.primary500}
            weight="regular"
            whiteSpace={isMobile ? "wrap" : "nowrap"}
          >
            {t("footer.company.infos")}
          </Body>
          {!isMobile && (
            <>
              <Space fluid />
              <NavLink to="/changelog">
                <Body
                  size="xx-small"
                  weight="regular"
                  decoration="underline"
                  color={palette.secondary800}
                >
                  {t("footer.link.changelog")}
                </Body>
              </NavLink>
              <Space units={2} />
              <NavLink to="/terms-and-conditions">
                <Body
                  size="xx-small"
                  weight="regular"
                  decoration="underline"
                  color={palette.secondary800}
                >
                  {t("footer.link.tnc")}
                </Body>
              </NavLink>
              <Space units={2} />
              <NavLink to="/report-a-bug">
                <Body
                  size="xx-small"
                  weight="regular"
                  decoration="underline"
                  color={palette.secondary800}
                >
                  {t("footer.link.bug")}
                </Body>
              </NavLink>
            </>
          )}
        </Box>
      </Column>
      <Space units={6} />
    </Box>
  );
}
