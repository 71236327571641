import { createContext, useContext, useMemo, useState } from "react";

type Message = {
  title: string;
  description: string;
  id: string;
};

const useMessages = () => {
  const [messages, setMessages] = useState<Message[]>([]);

  function addMessage(message: Message) {
    setMessages(prevMessages => [...prevMessages, message]);
  }

  function getMessageById(messageId: string) {
    return messages.find(message => message.id === messageId);
  }

  return useMemo(
    () => ({
      messages,
      addMessage,
      getMessageById
    }),
    [messages]
  );
};

const MessagesContext = createContext<ReturnType<typeof useMessages> | null>(
  null
);

export const useMessagesContext = () => useContext(MessagesContext)!;

export function MessagesProvider({ children }: { children: React.ReactNode }) {
  return (
    <MessagesContext.Provider value={useMessages()}>
      {children}
    </MessagesContext.Provider>
  );
}
