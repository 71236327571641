import { Box } from "@fifthbeat/design-system";
import { NavLink } from "react-router-dom";
import logoImg from "../../assets/careers-logo.webp";
import * as classes from "./Logo.css";

export function Logo() {
  return (
    <Box>
      <NavLink to="/">
        <img className={classes.logo} src={logoImg} alt="Logo" />
      </NavLink>
    </Box>
  );
}
