import { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { AuthRoute } from "./AuthRoute";
import { AlertsBox } from "~/blocks/AlertsBox/AlertsBox";
import { CypressHistorySupport } from "cypress-react-router";
import { RouterLoadingScreen } from "~/blocks/LoadingScreen/RouterLoadingScreen";
import { useUser } from "~/api/user";
import { useTokenContext } from "~/store/token";
import { useResponsive } from "~/hooks/useResponsive";
import { ScrollRestoreProvider } from "./ScrollRestoreProvider";

const Intro = lazy(() => import("./pages/Onboard/Intro"));
const Onboard = lazy(() => import("./pages/Onboard/Onboard"));
const Logout = lazy(() => import("./pages/Login/Logout"));
const Login = lazy(() => import("./pages/Login/Login"));
const AuthPageRedirect = lazy(() => import("./pages/AuthPageRedirect"));
const SingleSat = lazy(() => import("./pages/Sats/SingleSat"));
const ListSats = lazy(() => import("./pages/Sats/ListSats"));
const ListRole = lazy(() => import("./pages/Roles/ListRoles"));
const SingleRole = lazy(() => import("./pages/Roles/SingleRole"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Changelog = lazy(() => import("./pages/Changelog/Changelog"));
const Person = lazy(() => import("./pages/Person/Person"));
const People = lazy(() => import("./pages/People/People"));
const Incoming = lazy(() => import("./pages/Incoming/Incoming"));
const MobileCourtesy = lazy(() => import("./pages/MobileCourtesy"));

export function AppRouter() {
  const { token, verifyToken } = useTokenContext();
  const { data: user, isLoading: userIsLoading } = useUser();
  const { isMobile } = useResponsive();

  useEffect(() => {
    // !!token && refreshToken();
    !!token && verifyToken();
  }, [token, verifyToken]);

  if (userIsLoading) {
    return (
      <BrowserRouter>
        <RouterLoadingScreen />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <CypressHistorySupport />
      <AlertsBox />
      <Suspense fallback={<RouterLoadingScreen />}>
        <ScrollRestoreProvider>
          <Switch>
            <Route path={`/courtesy/mobile`}>
              <MobileCourtesy />
            </Route>

            <AuthRoute path="/incoming">
              <Incoming />
            </AuthRoute>

            {isMobile && <Redirect to={`/courtesy/mobile`} />}

            <Route exact path="/login">
              <Login />
            </Route>

            <Route path="/connect/:provider/redirect">
              <AuthPageRedirect />
            </Route>

            <Route exact path="/logout">
              <Logout />
            </Route>

            <AuthRoute path="/onboard">
              {user?.onboardCompleted ? (
                <Redirect to="/profile" />
              ) : (
                <>
                  <AuthRoute path="/onboard/intro">
                    <Intro />
                  </AuthRoute>
                  <AuthRoute path="/onboard/(role|skills|tools|activities)">
                    <Onboard />
                  </AuthRoute>
                </>
              )}
            </AuthRoute>

            <AuthRoute exact path="/">
              {user?.onboardCompleted ? (
                <Redirect to="/profile" />
              ) : (
                <Redirect to="/onboard/intro" />
              )}
            </AuthRoute>

            <AuthRoute exact path="/profile">
              <Profile />
            </AuthRoute>

            <AuthRoute path="/profile/:section/:satid?">
              <Profile />
            </AuthRoute>

            <AuthRoute exact path="/(skill|tool|activity)">
              <ListSats />
            </AuthRoute>
            <AuthRoute exact path="/(skill|tool|activity|sat)/:satid">
              <SingleSat />
            </AuthRoute>

            <AuthRoute exact path="/roles">
              <ListRole />
            </AuthRoute>

            <AuthRoute path="/roles/:roleid">
              <SingleRole />
            </AuthRoute>

            <AuthRoute exact path="/people">
              <People />
            </AuthRoute>

            <AuthRoute path="/people/:userid">
              <Person />
            </AuthRoute>

            {/* <AuthRoute path="/incoming">
            <Incoming />
          </AuthRoute> */}

            <AuthRoute path="/people/:userid">
              <Person />
            </AuthRoute>

            <AuthRoute path="/changelog">
              <Changelog />
            </AuthRoute>

            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </ScrollRestoreProvider>
      </Suspense>
    </BrowserRouter>
  );
}
