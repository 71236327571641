import { Box, PaletteColor, Space } from "@fifthbeat/design-system";
import { ComponentProps } from "react";
import * as classes from "./Column.css";
import cx from "classnames";

type Props = {
  children: ComponentProps<typeof Box>["children"];
  leftColor?: PaletteColor;
  rightColor?: PaletteColor;
  withMenu?: boolean;
};

export function Column(props: Props) {
  return (
    <Box grow basis="100%" className={classes.outer} hAlignContent="center">
      <Space
        fluid
        style={{
          backgroundColor: props.leftColor
        }}
      />
      <div
        className={cx(classes.inner, {
          [classes.withMenu]: props.withMenu
        })}
      >
        <Space
          fluid
          style={{
            backgroundColor: props.leftColor
          }}
        />
        {props.children}
        <Space
          fluid
          style={{
            backgroundColor: props.rightColor
          }}
        />
      </div>
      <Space
        fluid
        style={{
          backgroundColor: props.rightColor
        }}
      />
    </Box>
  );
}
