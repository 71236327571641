import {
  BellIcon,
  Box,
  palette,
  Stack,
  Divider,
  AvatarIcon,
  ExitIcon
} from "@fifthbeat/design-system";
import { User } from "~/api/user";
import { Userpic } from "~/components";
import { Dropdown } from "~/components/Dropdown/Dropdown";
import { DropdownItem } from "~/components/Dropdown/DropdownItem";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAlertContext } from "~/store/alert";

type Props = User & {};

export function HeaderUserMenu(props: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { addAlert } = useAlertContext();
  const userMenuItems = [
    {
      label: t("Header.Menu.MyPage"),
      icon: <AvatarIcon size="large" primaryColor={palette.accent400} />,
      action: () => history.push("/")
    },
    {
      label: t("logout"),
      icon: <ExitIcon size="large" primaryColor={palette.accent400} />,
      action: () => history.push("/logout")
    }
  ];

  return (
    <Stack units={2} vAlignContent="center">
      <Box
        style={{ cursor: "pointer" }}
        onClick={() =>
          addAlert({
            id: "notification-bell",
            status: "informative",
            title: "Feature not yet available",
            message: "This feature is not available yet, we are working for you"
          })
        }
      >
        <BellIcon size="large" primaryColor={palette.accent400} />
      </Box>
      <Divider orientation="vertical" />
      <Box>
        <Dropdown
          position={["bottom"]}
          opener={
            <DropdownItem
              data-test-id="header-user-menu"
              label={
                !!props.GivenName || !!props.GivenName
                  ? `${props.GivenName || ""} ${props.FamilyName || ""}`
                  : props.username
              }
              color={palette.neutral800}
              action={() => {}}
              icon={
                !!props.Picture ? (
                  <Userpic url={props.Picture} size="small" />
                ) : (
                  <AvatarIcon size="large" primaryColor={palette.accent400} />
                )
              }
            />
          }
        >
          <Stack column units={4} divider>
            {userMenuItems.map(item => (
              <DropdownItem key={item.label} {...item} />
            ))}
          </Stack>
        </Dropdown>
      </Box>
    </Stack>
  );
}
