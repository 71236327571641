import { useMediaQuery } from "react-responsive";

export function useResponsive() {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return {
    isBigScreen,
    isTablet,
    isMobile
  };
}
