import { Loading } from "~/components/Loading/Loading";
import { Box, Space } from "@fifthbeat/design-system";
import { ComponentProps } from "react";

type Props = {
  condition: boolean;
  children: ComponentProps<typeof Box>["children"];
};

export function LoadingScreen(props: Props) {
  switch (props.condition) {
    case false:
      return (
        <Box column grow vAlignContent="center" hAlignContent="center">
          <Space units={8} />
          <Loading />
        </Box>
      );
    case true:
      return <>{props.children}</>;
  }
}
