import {
  AvatarIcon,
  Box,
  palette,
  PaletteColor
} from "@fifthbeat/design-system";
import cx from "classnames";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import * as classes from "./Userpic.css";

type Props = {
  url: string | undefined;
  size: "small" | "medium" | "large" | "x-large" | "xxx-large";
  to?: string;
  margins?: "none" | "small" | "medium" | "large";
  borderColor?: PaletteColor;
};

export function Userpic(props: Props) {
  const [hasRendered, setHasRendered] = useState(false);

  let component = (
    <Box className={cx(classes.wrapper, classes.size[props.size])}>
      <AvatarIcon
        className={cx(classes.content, classes.size[props.size])}
        size="x-large"
        primaryColor={props.borderColor || palette.accent400}
      />
      <Box
        className={cx(classes.content, classes.size[props.size], classes.base, {
          [classes.margins[props.margins!]]: !!props.margins,
          [classes.withBorder]: !!props.borderColor
        })}
        style={{
          borderColor: props.borderColor || "auto",
          display: props.url && hasRendered ? "flex" : "none"
        }}
        component="img"
        src={props.url}
        onError={() => setHasRendered(false)}
        onLoad={() => setHasRendered(true)}
      />
    </Box>
  );

  switch (true) {
    case Boolean(props.to):
      return <NavLink to={props.to!}>{component}</NavLink>;
    default:
      return component;
  }
}
