import { TokenProvider } from "./store/token";
import { AlertProvider } from "~/store/alert";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { MessagesProvider } from "./store/messages";

const queryClient = new QueryClient();

export function AppProvider({ children }: { children: JSX.Element }) {
  return (
    <TokenProvider>
      <QueryClientProvider client={queryClient}>
        <AlertProvider>
          <MessagesProvider>{children}</MessagesProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </AlertProvider>
      </QueryClientProvider>
    </TokenProvider>
  );
}
