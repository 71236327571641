import { Header } from "..";
import * as classes from "./Layout.css";
import { Footer } from "~/blocks/Footer/Footer";
import { Box, Space } from "@fifthbeat/design-system";
import { ComponentProps } from "react";

type Props = {
  children: ComponentProps<typeof Box>["children"];
  backgroundImage?: string;
};

export function Layout(props: Props) {
  return (
    <Box
      component="main"
      column
      style={{
        backgroundImage: props.backgroundImage
          ? `url(${props.backgroundImage})`
          : ""
      }}
      className={classes.main}
    >
      <Header />
      {props.children}
      <Space fluid />
      <Footer />
    </Box>
  );
}
