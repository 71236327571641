import { useEffect } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { useTokenContext } from "~/store/token";

type Props = RouteProps;

export function AuthRoute(props: Props) {
  const { token, saveToken } = useTokenContext();
  const location = useLocation();
  const localToken = localStorage.getItem("token");

  useEffect(() => {
    if (!!localToken && localToken.toString() !== token?.toString()) {
      // console.log("before update token with local storage");
      saveToken(localToken);
      // console.log("after update token with local storage");
      localStorage.removeItem("login_redirect_url");
    }
  }, []);

  if (!localToken) {
    localStorage.setItem("login_redirect_url", location.pathname);
    return <Redirect to="/login" />;
  }

  return <Route {...props}>{props.children}</Route>;
}
