import { Box, palette, Stack, Subheading } from "@fifthbeat/design-system";
import { NavLink } from "react-router-dom";
import { useTranslation } from "../../i18n";
import * as classes from "./Header.css";
import routes from "./headeMenuLinks";

export function HeaderNavigationMenu() {
  const { t } = useTranslation();
  return (
    <Stack vAlignContent="center" units={2}>
      {routes.map(route => (
        <Box key={route.id}>
          <NavLink activeClassName={classes.activeLink} to={route.path}>
            <Subheading
              color={palette.primary500}
              size="xx-small"
              weight="regular"
              data-test-id={route["data-test-id"]}
            >
              {t(route.label)}
            </Subheading>
            <Box className={classes.activeLinkUnderline} grow />
          </NavLink>
        </Box>
      ))}
    </Stack>
  );
}
