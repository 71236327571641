import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "normalize.css";
import "./index.css";
import "./i18n";
import "@fifthbeat/design-system/style.css";
import { createBrowserHistory } from "history";
import ErrorBoundry from "./ErrorBoundry";
import { RouterLoadingScreen } from "~/blocks/LoadingScreen/RouterLoadingScreen";

const history = createBrowserHistory();
let app = document.getElementById("root");
if (app) {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
}

console.log(import.meta.env.MODE, import.meta.env);

ReactDOM.render(
  <StrictMode>
    <ErrorBoundry>
      <Suspense fallback={<RouterLoadingScreen />}>
        <App />
      </Suspense>
    </ErrorBoundry>
  </StrictMode>,
  app
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
