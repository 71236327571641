import { Box, Space } from "@fifthbeat/design-system";
import { Logo } from "../../components";
import * as classes from "./Header.css";

import { HeaderUserMenu } from "./HeaderUserMenu";
import { HeaderNavigationMenu } from "./HeaderNavigationMenu";
import { Column } from "~/components/Grid/Column";
import { useUser } from "~/api/user";

export function Header() {
  const { data: userData } = useUser();

  return (
    <Box
      component="header"
      hAlignContent="center"
      vAlignContent="center"
      className={classes.wrapper}
    >
      <Column>
        <Box column hAlignContent="center">
          <Box vAlignContent="center" grow width={!userData ? "auto" : "100%"}>
            <Box>
              <Logo />
              {!!userData && userData.onboardCompleted && (
                <>
                  <Space units={8} />
                  <HeaderNavigationMenu />
                </>
              )}
            </Box>
            <Space fluid />
            <Box>{!!userData && <HeaderUserMenu {...userData} />}</Box>
          </Box>
        </Box>
      </Column>
    </Box>
  );
}
