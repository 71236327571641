import {
  Box,
  palette,
  PaletteColor,
  Space,
  Subheading
} from "@fifthbeat/design-system";
import { ComponentProps } from "react";
import * as classes from "./DropdownItem.css";

type Props = {
  label: string;
  action: () => void;
  icon?: ComponentProps<typeof Box>["children"];
  color?: PaletteColor;
  "data-test-id"?: string;
};

export function DropdownItem({
  color = palette.secondary800,
  ...props
}: Props) {
  return (
    <Box
      onClick={() => props.action()}
      vAlignContent="center"
      className={classes.wrapper}
    >
      {Boolean(props.icon) && (
        <>
          {props.icon}
          <Space units={2} />
        </>
      )}
      <Subheading
        data-test-id={props["data-test-id"]}
        color={color}
        size="xx-small"
        weight="regular"
      >
        {props.label}
      </Subheading>
    </Box>
  );
}
