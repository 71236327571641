import { Box, Space } from "@fifthbeat/design-system";
import { Column, Logo } from "~/components";
import { LoadingScreen } from "~/blocks";
import * as classes from "./RouterLoadingScreen.css";

export function RouterLoadingScreen() {
  return (
    <Box column grow className={classes.wrap}>
      <Box className={classes.header}>
        <Column>
          <Box vAlignContent="center">
            <Logo />
          </Box>
        </Column>
      </Box>
      <Box className={classes.pageHeader} />
      <Box column grow className={classes.content}>
        <LoadingScreen condition={false}>
          <Box />
        </LoadingScreen>
      </Box>
      <Box className={classes.footer} />
    </Box>
  );
}
