import { Box, spaceUnit } from "@fifthbeat/design-system";
import { useEffect, ComponentProps, useRef, useState } from "react";
import { Popover } from "react-tiny-popover";
import * as classes from "./Dropdown.css";

type Props = {
  children: ComponentProps<typeof Box>["children"];
  opener: ComponentProps<typeof Box>["children"];
  position?: ("top" | "bottom" | "left" | "right")[];
};

// TODO: refactor this using usePopover from @fifthbeat/design-system
export function Dropdown(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const openerRef = useRef<HTMLDivElement>(null);
  const [boxWidth, setBoxWidth] = useState<number | undefined>();

  useEffect(() => {
    if (openerRef.current) {
      setBoxWidth(openerRef.current?.offsetWidth);
    }
  }, []);

  return (
    <Popover
      ref={openerRef}
      containerClassName={classes.wrapper}
      padding={0}
      align="start"
      positions={!!props.position ? props.position : ["top", "bottom"]}
      reposition={true}
      content={
        <Box
          grow
          width={!!boxWidth ? boxWidth + spaceUnit * 4 : "auto"}
          column
          className={classes.content}
        >
          {props.children}
        </Box>
      }
      isOpen={isOpen}
      onClickOutside={() => setIsOpen(false)}
    >
      <Box
        ref={openerRef}
        onClick={() => setIsOpen(!isOpen)}
        className={classes.opener}
      >
        {props.opener}
      </Box>
    </Popover>
  );
}
